<template>
    <el-dialog title="新建术语库" center :visible.sync="dialog.show" destroy-on-close width="430px" top="20px" @close="handleDialogClose">
        <el-form ref="newDialogForm" size="medium" label-position="top">
            <el-form-item label="名称"  required>
                <el-input placeholder="输入术语库名称" v-model="dialog.form.name"></el-input>
            </el-form-item>
            <el-form-item label="语言对" required>
                    <el-select v-model="dialog.form.sourceLang" placeholder="源语言" @change="sourceLangChange" style="width:126px;">
                        <template v-for="(item,key) in LANGUAGE_DICT">
                            <el-option :key="key" :value="key" :label="item.name"></el-option>
                        </template>
                    </el-select>
                    <svg-icon name="ib-arrow-right" className="fonts-20 margin-x-5"></svg-icon>
                    <el-select v-model="dialog.form.targetLang" placeholder="目标语言" style="width:126px;">
                        <template v-for="(item,key) in LANGUAGE_DICT">
                            <el-option :key="key" :value="key" :label="item.name" :disabled="dialog.form.sourceLang === key"></el-option>
                        </template>
                    </el-select>
            </el-form-item>
            <el-form-item label="所属团队" required v-if="userInfo && userInfo.organizationType === 'ENTERPRISE'">
                <el-select placeholder="选择团队" v-model="dialog.form.teamId" style="width:126px;">
                    <template v-for="item in teamOptions">
                        <el-option :key="item.value" :value="item.value" :label="item.text"></el-option>
                    </template>
                </el-select>
            </el-form-item>
            <el-form-item label="上传术语" v-if="userInfo">
                <div class="fonts-12 margin-b-16 line-height-0">
                    支持xlsx/xls/tbx/sdltb，最大{{userInfo.featureConfig.singleFileSize}}M。点击下载模版：
                    <el-button type="text" class="color-link padding-0" :disabled="dialog.templateLoading" @click="downloadTemplateEvent">excel</el-button>
                </div>
                <upload-oss :multiple="false" ref="uploadOssDom" accept=".xls,.xlsx,.tbx,.sdltb" :size="userInfo.featureConfig.singleFileSize" :params="{}"></upload-oss>
            </el-form-item>
        </el-form>
        <div slot="footer">
            <el-button round plain size="small" @click="dialog.show = false">取消</el-button>
            <el-button round type="primary" size="small" style="margin-left:30px;" :loading="dialog.loading" @click="saveBtnEvent">保存</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import cookie from 'vue-cookies';
import fileDownload from 'js-file-download';
import config from '@/utils/config';
import { jargonApi, termApi, commonApi } from '@/utils/api';
import UploadOss from '@/components/UploadOss';
export default {
    components: { UploadOss },
    props:{
        source:{
            type: String,
            default: '',
        },
        target:{
            type: String,
            default: '',
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    },
    data(){
        return{
            uploadUrl:termApi.uploadTermFile,
            uploadBtnLoading:false,
            uploadStatus:'', //空=不显示，1=成功，0=失败
            uploadFileName:'',
            uploadHeaders:{
                'Authorization':cookie.get("token")
            },
            dialog:{
                show:false,
                loading:false,
                templateLoading:false,
                form:{
                    name:'',
                    sourceLang:'',
                    targetLang:'',
                    filePath:'',
                    teamId: '',
                }
            },
            teamOptions: [],
        }
    },
    methods:{
        sourceLangChange(val){
            if(val === this.dialog.form.targetLang){
                if(val === 'zh'){
                    this.dialog.form.targetLang = 'en';
                }else{
                    this.dialog.form.targetLang = 'zh';
                }
            }
        },
        downloadTemplateEvent(){
            let downMsg = this.$message({
                message: '下载中...',
                type: 'info',
                iconClass: 'el-icon-loading',
                duration: 0,
            });
            this.dialog.templateLoading = true;
            this.$ajax.get(jargonApi.downloadTemplate).then(res=>{
                if(res.status === 200){
                    if(res.data){
                        window.open(res.data);
                    }else {
                        this.$message.error('获取下载地址失败');
                    }
                }
            }).finally(()=>{
                this.dialog.templateLoading = false;
                downMsg.close();
            })
        },
        saveBtnEvent(){
            let fileList = this.$refs.uploadOssDom.getOssFiles();
            let url = termApi.termDB;
            let postData = this.dialog.form;
            if(!postData.name){
                this.$message.error('术语库名称必须填写');
                return;
            }
            if(!postData.sourceLang || !postData.targetLang){
                this.$message.error('语言对的源语言和目标语言都必须选择');
                return;
            }
            postData.filePath = fileList;
            this.dialog.loading = true;
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success('术语库创建成功');
                    this.dialog.show = false;
                    this.$emit('success', res.data);
                }
            }).finally(()=>{
                this.dialog.loading = false;
            })

        },
        setShow(val){
            this.dialog.show = val;
            this.dialog.form.sourceLang = this.source;
            this.dialog.form.targetLang = this.target;
            if(this.userInfo.organizationType === 'ENTERPRISE') {
                this.queryTeamOptions();
            }
        },
        handleDialogClose(){
            this.dialog.form = {
                name:'',
                sourceLang:'',
                targetLang:'',
                filePath:'',
                teamId: '',
            };
            this.uploadFileName = '';
            this.uploadStatus = '';
        },
        queryTeamOptions() {
            let url = commonApi.queryTeamFilterOptions;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.teamOptions = res.data.teamFilters;
                    this.dialog.form.teamId = this.teamOptions[0].value
                }
            });
        },
    },
}
</script>
