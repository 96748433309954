<!-- 个人用户创建项目 -->
<template>
	<div class="index-page">
		<div class="steps-content">
			<template v-for="(step, index) in steps">
				<div :class="['step-item', activeStepIndex === index ? 'active' : '']" :key="index">
					<div class="step-name">{{ index + 1 }}.{{ step }}</div>
					<div class="step-arrow" v-if="index < steps.length - 1">
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
			</template>
		</div>
		<div class="upload-content" v-show="activeStepIndex === 0">
			<create-upload ref="createUploadDom" @action="executeAction"></create-upload>
			<div class="next-btn">
				<el-button type="primary" round :loading="nextBtnLoading" @click="nextBtnEvent" v-if="Object.keys(fileData).length > 0">下一步</el-button>
			</div>
		</div>
		<div class="engine-content" v-show="activeStepIndex === 1">
			<div class="from-content">
				<language-selector ref="languageSelectorDom" :default="{source: form.originValue, target: form.targetValue}" :open="false" :auto="false" @change="languageSelectedChange"></language-selector>
			</div>
			<div class="try-content">
				<div class="left-content">
					<div class="input-content" :lang="form.originValue">
						<el-input
							type="textarea"
							resize="none"
							:autosize="{ minRows: 14, maxRows: 25 }"
							placeholder="粘贴一段文字，快速对比不同引擎翻译结果，每次最多2000字"
							maxlength="2000"
							autofocus
							@input="docValueChangeEvent"
							v-model="form.docValue"
						>
						</el-input>
						<div class="clear-btn" v-show="form.docValue.length > 0" @click="clearDocValueEvent">
							<i class="el-icon-close"></i>
						</div>
					</div>
					<div class="action-content">
						<div class="random-doc-btn" :disabled="randomDocBtnLoading" >
                            <template  v-if="randomDocBtnShow">
                                <span @click="randomDocBtnEvent">
                                    <svg-icon name="ib-refresh" :className="randomDocBtnLoading ? 'animation-spin' : ''"></svg-icon> {{form.docValue?'换一换':'试一试样例'}}
                                </span>
                            </template>
                        </div>
                        <div class="tag-content">
                            <span>{{randomSentenceLable}}</span>
                        </div>
						<div class="word-number">
							{{ form.docValue ? form.docValue.length : 0 }}/2000
						</div>
					</div>
				</div>
				<div class="center-content">
					<div class="field-type-list">
						<template v-for="domain in domainAndEngineList">
							<div :class="['item', domain.key === selectedDomain.key ? 'active' : '']" :key="domain.key" @click="domainClickEvnet(domain)">
								{{ domain.name }}
							</div>
						</template>
					</div>
					<div class="engine-list" v-if="selectedDomain">
                        <template>
                            <template v-for="engine in engineList">
                                <div :class="['item', (selectedEngine && engine.key === selectedEngine.key) ? 'active' : '']"
                                    :disabled="(selectedEngine && engine.key === selectedEngine.key && queryTransitionResultLoading) || !engine.enabled"
                                    :key="engine.key"
                                    @click="changeEngineEvent(engine)">
                                    <div class="engine-icon">
                                        <i class="el-icon-loading" v-if="selectedEngine && engine.key === selectedEngine.key && queryTransitionResultLoading"></i>
                                        <svg-icon :name="'ie-' + engine.icon" v-else></svg-icon>
                                    </div>
                                    <div class="engine-name">{{ engine.name }}</div>
                                    <template v-if="selectedDomain.key === 'personal'">
                                        <el-tooltip content="查看引擎详情" placement="top">
                                            <div class="engine-more" @click.stop="gotoDetailPage(engine)">
                                                <svg-icon name="ib-arrow-right"></svg-icon>
                                            </div>
                                        </el-tooltip>
                                    </template>
                                    <div class="triangle-mark" v-if="engine.proUsed"><span>PRO</span></div>
                                </div>
                            </template>
                        </template>
						<template v-if="selectedDomain.key === 'personal'">
                            <el-tooltip content="新建引擎" placement="top">
                                <div class="item add-engine" @click="gotoNewPage">
                                    <svg-icon name="ib-plus"></svg-icon>
                                </div>
                            </el-tooltip>
						</template>
					</div>
                <template v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType) < 0">
                    <div class="fonts-12 margin-b-5" v-if="selectedDomain && selectedDomain.key === 'llm'">部分大模型翻译速度较慢，如需翻译文档，建议优先LanguageX和ChatGPT</div>
                </template>
				</div>
				<div class="right-content" :lang="form.targetValue" v-html="form.resultValue"></div>
			</div>
            <div class="next-btn">
                <el-button plain round @click="backBtnEvent">上一步</el-button>
				<el-button type="primary" round @click="nextBtnEvent" style="margin-left: 40px">下一步</el-button>
			</div>
			
		</div>
        <div class="setting-content" v-show="activeStepIndex === 2">
            <el-card class="none-boder radius-12 margin-t-20" :body-style="{padding:'30px'}">
                <div class="project-info margin-b-20">
                    <div class="flex">
                        <div class="flex-1"><!-- #E9F8F2 -->
                            <el-card shadow="never" class="none-boder radius-12 margin-b-10" :body-style="{backgroundColor: '#F8F8F8',padding:'15px 20px'}">
                                <div class="margin-b-10 fonts-12">项目名称</div>
                                <div>
                                    <el-input ref="defaultProjectName" v-model="defaultProjectName" placeholder="输入项目名称" autofocus="true"></el-input>
                                </div>
                            </el-card>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="flex-1 margin-r-10">
                            <el-card shadow="never" class="none-boder radius-12" :body-style="{backgroundColor: '#F8F8F8',padding:'15px 20px'}">
                                <div class="margin-b-10 fonts-12">机翻引擎</div>
                                <div class="fonts-15 flex flex-align-center text-weight-600" v-if="selectedEngine">
                                    <svg-icon :name="'ie-'+selectedEngine.icon" className="fonts-20 margin-r-8"></svg-icon> <span style="line-height: 32px;">{{selectedEngine.name}}</span>
                                </div>
                            </el-card>
                        </div>
                        <div class="flex-1">
                             <el-card shadow="never" class="none-boder radius-12" :body-style="{backgroundColor: '#F8F8F8',padding:'15px 20px'}">
                                <div class="margin-b-10 fonts-12">语言方向</div>
                                <div class="fonts-15 text-weight-600" style="line-height: 32px;">
                                    {{LANGUAGE_DICT[form.originValue].name}}
                                    <svg-icon name="ib-arrow-right" className="margin-x-10 fonts-16"></svg-icon>
                                    {{LANGUAGE_DICT[form.targetValue].name}}
                                </div>
                            </el-card>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="tab-list">
                        <template v-for="(item, index) in tabData.tabs">
                            <div :class="['item', tabData.tabActiveIndex === index ? 'active' : '']" :key="index" @click="settingTabEvent(item, index)">{{item}}</div>
                        </template>
                    </div>
                    <div v-show="tabData.tabActiveIndex === 0">
                        <div class="fonts-14 margin-t-15">系统会自动创建该项目默认记忆库，并将项目新产生的翻译记忆写入此库。</div>
                        <div class="flex flex-justify-between margin-t-15">
                            <div>
                                <el-button size="mini" plain round @click="gotoMemoryPage" icon="el-icon-plus">新建</el-button>
                                <el-button size="mini" plain round @click="initMemoryList" icon="el-icon-refresh-right">刷新</el-button>
                            </div>
                            <div class="fonts-13 mem-matching-setting">
                                <span class="margin-r-10"><span class="color-error fonts-16" style="margin-right:1px;">*</span>匹配率</span>
                                <el-select placeholder="匹配率" v-model="memMatchingRate" size="mini" style="width:78px;">
                                    <template v-for="item in memMatchingOptions">
                                        <el-option :label="item" :value="item" :key="item"></el-option>
                                    </template>
                                </el-select>
                            </div>
                        </div>
                        <el-table ref="memoryTable" :data="tabData.memoryList" max-height="480" style="width: 100%" @selection-change="handleMemoryTableSelectionChange">
                            <el-table-column type="selection" width="50"></el-table-column>
                            <el-table-column label="名称">
                                <template slot-scope="scope">
                                    <router-link :to="{path:'/asset/memory/detail',query:{id:scope.row.memId}}" target="_blank">{{scope.row.name}}</router-link>
                                </template>
                            </el-table-column>
                            <el-table-column label="语言方向">
                                <template slot-scope="scope">
                                    <language-direction :source="scope.row.sourceLang" :target="scope.row.targetLang"></language-direction>
                                </template>
                            </el-table-column>
                            <el-table-column label="条数" prop="nums" width="100">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.nums }}条</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="创建时间" prop="createTime" width="170"></el-table-column>
                        </el-table>
                    </div>
                    <div v-show="tabData.tabActiveIndex === 1">
                        <el-alert type="info" effect="dark" :closable="false">
                            <template slot="title">
                                <div class="flex flex-align-center" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1">
                                    <svg-icon name="icon-free" style="font-size:22px;" class="margin-r-10"></svg-icon>项目术语会在编辑器提示，但当前版本不支持术语干预，<a href="/account/vip" target="_blank">升级到PRO解锁本功能</a>
                                </div>
                                <div class="flex flex-align-center" v-else>
                                    <svg-icon name="ic-pro" style="font-size:22px;" class="margin-r-10"></svg-icon>{{userInfo.accountTypeDesc}}已支持术语干预
                                </div>
                            </template>
                        </el-alert>
                        <div class="fonts-14 margin-t-15">系统会自动创建该项目默认术语库，并将项目新产生的术语写入此库。</div>
                        <div class="margin-t-15">
                            <el-button size="mini" plain round @click="gotoTermPage" icon="el-icon-plus">新建</el-button>
                            <el-button size="mini" plain round @click="initTermsList" icon="el-icon-refresh-right">刷新</el-button>
                        </div>
                        <el-table ref="termsTable" :data="tabData.termsList" max-height="480" style="width: 100%" @selection-change="handleTermsTableSelectionChange">
                            <el-table-column type="selection" width="50"></el-table-column>
                            <el-table-column label="名称">
                                <template slot-scope="scope">
                                    <router-link :to="{path:'/asset/term/detail',query:{id:scope.row.termId}}" target="_blank">{{scope.row.name}}</router-link>
                                </template>
                            </el-table-column>
                            <el-table-column label="语言方向">
                                <template slot-scope="scope">
                                    <language-direction :source="scope.row.sourceLang" :target="scope.row.targetLang"></language-direction>
                                </template>
                            </el-table-column>
                            <el-table-column label="条数" prop="nums" width="100">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.nums }}条</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="创建时间" prop="createTime" width="170"></el-table-column>
                        </el-table>
                    </div>
                    <div class="padding-t-10" v-show="tabData.tabActiveIndex === 2">
                        <!-- <div class="padding-b-15 border-b fonts-14">拆分规则</div>
                        <div class="doc-config-card" shadow="never">
                            <div class="flex flex-align-center">
                                <div class="flex-1">
                                    <el-radio-group v-model="docSplit">
                                        <el-radio label="SENTENCE">按句拆分</el-radio>
                                        <el-radio label="PARAGRAPH">按段拆分</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="padding-y-15 border-b fonts-14">文档设置</div> -->
                        <template v-for="(item, index) in docSettingOpts">
                            <div class="doc-config-card" shadow="never" :key="index">
                                <div class="flex flex-align-center">
                                    <el-tooltip :content="item.name" placement="top">
                                        <div class="doc-icon file-word flex flex-justify-center flex-align-center">
                                            <svg-icon :name="'ic-' + item.icon" style="width: 17px;height: 17px;"></svg-icon>
                                        </div>
                                    </el-tooltip>
                                    <div class="check-all margin-l-30" style="width: 87px;">
                                        <el-checkbox v-model="item.checkAll" :indeterminate="item.indeterminate" @change="val=>docSettingCheckAllChange(val,index)">全选</el-checkbox>
                                    </div>
                                    <div class="flex-1">
                                        <div class="options-list">
                                            <template v-for="(opt, oIndex) in item.options">
                                                <div class="margin-r-30"  :key="oIndex">
                                                    <el-checkbox v-model="opt.value" :label="opt.value" @change="val=>docSettingCheckChange(val,index,oIndex)">{{opt.label}}</el-checkbox>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="padding-t-10" v-show="tabData.tabActiveIndex === 3">
                        <div class="qa-setting margin-t-10">
                            <div class="block-title">规则QA设置</div>
                            <div class="option-list">
                                <template v-for="(item,index) in qaSetting.basicList">
                                    <div class="item" :key="index">
                                        <el-checkbox v-model="item.selected" :disabled="!item.enabled" :label="item.description"></el-checkbox>
                                    </div>
                                </template>
                            </div>
                            <div class="block-title">
                                智能QA设置
                                <!-- <el-tooltip content="智能QA只对Pro版开放，Free用户QA只提供规则QA" placement="top">
                                    <span style="color:#000;">
                                        <i class="el-icon-info"></i>
                                    </span>
                                </el-tooltip> -->
                            </div>
                            <div class="option-list">
                                <template v-for="(item,index) in qaSetting.aiList">
                                    <div class="item" :key="index">
                                        <el-checkbox v-model="item.selected" :disabled="!item.enabled" :label="item.description"></el-checkbox>
                                    </div>
                                </template>
                            </div>
                            <!-- <div style="font-size:12px;">
                                <svg-icon name="ib-warning" style="width: 14px;height: 14px;fill:#FDB500;"></svg-icon> 英文流利度（包括用词搭配、时态、单复数、句首大小、代词/冠词、英文流利度检查）
                            </div> -->
                        </div>
                    </div>
                    <div class="padding-t-15" v-show="tabData.tabActiveIndex === 4">
                        <el-image style="display:none;" ref="backupEngineExample"
                            src="https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/backup-engine-example.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101677055427&Signature=fKZxWhwx5HfiOfUw6lmMV%2FkU8KA%3D"
                            :preview-src-list="[
                                'https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/backup-engine-example.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101677055427&Signature=fKZxWhwx5HfiOfUw6lmMV%2FkU8KA%3D'
                            ]"></el-image>
                        <div class="margin-b-15 fonts-12 color-666666">选择编辑器内参考引擎<a href="javascript:;" class="margin-l-10" @click="$refs.backupEngineExample.clickHandler()">预览</a></div>
                        <div class="backup-engine-wapper">
                            <template v-for="item in referenceEngineList">
                                <div class="group-name" :key="`name-${item.key}`">{{item.name}}</div>
                                <div class="engine-group" :key="`group-${item.key}`">
                                    <template v-for="engine in item.engines">
                                        <div :class="{'item': true, 'active': selectReferenceEngine && engine.key === selectReferenceEngine.key}" :key="`engine-${engine.key}`" @click="referenceEngineEvent(engine)">
                                            <svg-icon :name="`ie-${engine.icon}`"></svg-icon>
                                            <span class="name">{{engine.name}}</span>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </el-card>
            <div class="action-content" v-if="activeStepIndex === 2">
                <el-button plain round @click="backBtnEvent">上一步</el-button>
                <el-button type="primary" round @click="startTransitionBtnEvent" :loading="startTranslationBtnLoading" style="margin-left: 40px">开始翻译</el-button>
            </div>
        </div>
        
		<create-memory ref="createMemoryDialog" @success="initMemoryList"></create-memory>
        <create-term ref="createTermDialog" @success="initTermsList"></create-term>
		<alert-dialog
			ref="alertDialogDom"
			:message="alertInfo.message"
			:submessage="alertInfo.submessage"
			:type="alertInfo.type"
			:buttonName="alertInfo.buttonName"
			:buttonType="alertInfo.buttonType"
			@confirmEvent="confirmNextEvent"
		></alert-dialog>
	</div>
</template>
<script>
import { debounce } from 'lodash';
import { mapGetters, mapState } from "vuex";
import $ from "jquery";
import CreateUpload from './components/CreateUpload.vue';
import JargonDialog from "@/components/JargonDialog";
import AlertDialog from "@/components/AlertDialog";
import PageTitle from "@/components/PageTitle";
import LanguageSelector from "@/components/LanguageSelector";
import CreateTerm from '@/views/assets/components/CreateTerm';
import CreateMemory from '@/views/assets/components/CreateMemory';
import LanguageDirection from '@/components/LanguageDirection';
import config from "@/utils/config";
import tools from "@/utils/tools";
import { commonApi, projectApi, docApi, termApi, memoryApi, qaApi } from "@/utils/api";

export default {
	data() {
		return {
            uploadFileList: [],
            fileData: {},
			steps: ['上传文件', '选择机器翻译引擎', '项目设置'],
			activeStepIndex: 0,
            nextBtnLoading: false,
			customTermId: "",
			domainAndEngineList: [],
			engineList: [],
			selectedDomain: null,
			selectedEngine: null,
			defaultProjectName: "",
			form: {
				originValue: "zh",
				targetValue: "en",
				docValue: "",
				resultValue: "",
			},
			docValueInputTimer: null,
			
			startTranslationBtnLoading: false,
			// advancedIsShow: false,
            memMatchingRate: 80,
            memMatchingOptions: [50, 60, 70, 80, 90, 100,],
			tabData: {
                tabs: ['记忆库', '术语库', '文档设置', 'QA设置', '参考引擎'],
				tabActiveIndex: 0,
				termsList: [],
				termsTableSelection: [],
				memoryList: [],
				memoryTableSelection: [],
			},
			alertInfo: {
				message: "当前上传列表中有上传中或上传失败的文件，是否继续？",
				submessage: "点击继续按钮，上传中或上传失败的文件不会带到下一步。",
				type: "confirm", //alert ,confirm
				buttonName: "继续",
				buttonType: "primary",
			},
            createProjectApiSuccess: false,
			queryTransitionResultLoading: false,
            teamId: '',
            remark: '',
            docSplit: 'SENTENCE',
            docSplitOpts: config.DOC_SPLIT_OPTS,
            docSettingOpts: config.DOC_SETTING_OPTS || [],
            qaSetting: {
                basicList:[],
                aiList:[],
            },

            // 随机句段
            randomDocBtnLoading: false,
            randomDocBtnShow: false,
            randomSentences: [],
            randomSentenceLable: '',

            referenceEngineList: [],
            selectReferenceEngine: null,
		};
	},
    computed: {
        ...mapGetters({
            userInfo: 'user/userInfo',
        }),
        ...mapState({
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
	},
	components: {
		JargonDialog,
		PageTitle,
		AlertDialog,
		LanguageSelector,
        CreateTerm,
        CreateMemory,
        CreateUpload,
        LanguageDirection,
	},
	methods: {
		nextBtnEvent() {
            if (this.activeStepIndex === 0){
                let {fileList, fileData} = this.$refs.createUploadDom.getValue();
                let keys = Object.keys(fileData || {});
                if (keys.length < fileList.length) {
                    this.$refs.alertDialogDom.setShow(true);
                } else {
                    if (keys.length > 0) {
                        let list = [];
                        keys.map(key=>{
                            list.push(fileData[key]);
                        });
                        if (list.length > 1){
                            list.sort((a,b)=>{
                                return a.size - b.size;
                            });
                        }
                        let url = `${docApi.getDocLanguage}?objectName=${list[0].objectName}`;
                        this.nextBtnLoading = true;
                        this.$ajax.get(url).then(res => {
                            if (res.status === 200){
                                let lang = res.data;
                                if (lang === 'unknown'){
                                    this.form.originValue = 'zh';
                                }else{
                                    this.form.originValue = lang;
                                    if (lang === this.form.targetValue){
                                        if(lang === 'zh'){
                                            this.form.targetValue = 'en';
                                        }else{
                                        this.form.targetValue = 'zh'; 
                                        }
                                    }
                                }
                                this.activeStepIndex = this.activeStepIndex + 1;
                                let _name = fileList[0].name;
                                let _names = _name.split('.');
                                _names.pop();
                                this.defaultProjectName = _names.join('.');
                                //初始化引擎列表
                                this.getEngineList();
                            }
                        }).finally(()=>{
                            this.nextBtnLoading = false;
                        });   
                    } else {
                        if (fileList.length > 0) {
                            this.$message.error("请检查文件是否上传成功");
                        } else {
                            this.$message.error("请上传文件");
                        }
                    }
                }
            }else if(this.activeStepIndex === 1){
                if(!this.selectedEngine){
                    this.$message.error('请选择引擎');
                    return;
                }

                this.docSplit = 'SENTENCE';
                if(this.selectedEngine.key === 'chatgpt') {
                    this.docSplit = 'PARAGRAPH';
                }

                this.activeStepIndex = this.activeStepIndex + 1;
                this.$nextTick(()=>{
                    this.$refs.defaultProjectName.focus();
                });
                // 初始换术语库记忆库
                this.initTermsList();
                this.initMemoryList();
                this.initReferenceEngineList();
            }else{
                this.activeStepIndex = this.activeStepIndex + 1;
            }
		},
		confirmNextEvent() {
            let {fileList, fileData} = this.$refs.createUploadDom.getValue();
			let keys = Object.keys(fileData || {});
			if (keys.length > 0) {
                let list = [];
                keys.map(key=>{
                    list.push(fileData[key]);
                });
                if (list.length > 1){
                    list.sort((a,b)=>{
                        return a.size - b.size;
                    });
                }
                let url = `${docApi.getDocLanguage}?objectName=${list[0].objectName}`;
                this.nextBtnLoading = true;
                this.$ajax.get(url).then(res => {
                    if (res.status === 200){
                        let lang = res.data;
                        if (lang === 'unknown'){
                            this.form.originValue = 'zh';
                        }else{
                            this.form.originValue = lang;
                            if (lang === this.form.targetValue){
                                if(lang === 'zh'){
                                    this.form.targetValue = 'en';
                                }else{
                                   this.form.targetValue = 'zh'; 
                                }
                            }
                        }
                        this.activeStepIndex = 1;
                        let _name = fileList[0].name;
                        let _names = _name.split('.');
                        _names.pop();
                        if (!this.defaultProjectName) {
                            this.defaultProjectName = _names.join('.');
                        }
                        //初始化引擎列表
                        this.getEngineList();
                    }
                }).finally(()=>{
                    this.nextBtnLoading = false;
                });  
				
			} else {
				if (fileList.length > 0) {
					this.$message.error("请检查文件是否上传成功");
				} else {
					this.$message.error("请上传文件");
				}
			}
		},
        
		getEngineList() {
			let index = 0;
			let url = `${projectApi.queryEnginesByLang}?sourceLang=${this.form.originValue}&targetLang=${this.form.targetValue}&isLimited=true`;
			this.$ajax.get(url).then((res) => {
				if (res.status === 200) {
					this.domainAndEngineList = res.data;
					if (this.selectedDomain) {
						let key = this.selectedDomain.key;
						let tmpDomain = res.data.filter(
							(item) => item.key === key && item
						)[0];
						this.selectedDomain = tmpDomain;
					} else {
						this.selectedDomain = res.data[index];
					}
                    this.engineList = this.selectedDomain.engines;
                    this.setSelectedEngine(this.selectedDomain);
					this.getEngineTransition();
                    this.getRandomSentences();
				}
			});
		},
		
		domainClickEvnet(domain) {
			this.selectedDomain = domain;
            this.engineList = domain.engines;
            this.setSelectedEngine(domain);

            this.getEngineTransition();
            this.getRandomSentences();
		},
        /**
         * 设置默认选择的引擎
         */
        setSelectedEngine(domain){
            if (domain.engines.length > 0) {
				if (this.selectedEngine) {
					let has = domain.engines.filter((item) => item.key === this.selectedEngine.key);
					if (has.length === 0) {
                        this.selectedEngine = null;
                        for(let engine of domain.engines){
                            if(engine.enabled && !engine.proUsed){
                                this.selectedEngine = engine;
                                break;
                            }
                        }
					}
				} else {
                    this.selectedEngine = null;
                    for(let engine of domain.engines){
                        if(engine.enabled && !engine.proUsed){
                            this.selectedEngine = engine;
                            break;
                        }
                    }
				}
            }else{
                this.selectedEngine = null;
            }
        },
		exchangeLanguage() {
			let tmpOriginValue = this.form.originValue;
			let tmpTargetValue = this.form.targetValue;
			this.form.originValue = tmpTargetValue;
			this.form.targetValue = tmpOriginValue;
			this.getEngineList();
		},
		languageSelectedChange(val) {
			this.form.originValue = val.source.key;
			this.form.targetValue = val.target.key;
			this.getEngineList();
		},
        clearDocValueEvent(){
            this.form.docValue = '';
            this.form.resultValue='';
            this.randomSentenceLable = '';
        },
		docValueChangeEvent(value) {
            this.randomSentenceLable = '';
			if (this.docValueInputTimer) {
				clearTimeout(this.docValueInputTimer);
			}
			if (value) {
				this.docValueInputTimer = setTimeout(() => {
					this.getEngineTransition();
				}, 1000);
			} else {
				this.form.resultValue = "";
			}
		},
		
		/**
		 * 单个引擎翻译请求
		 */
		getEngineTransition() {
			if (!this.form.docValue) {
				return;
			}
            if(!this.selectedEngine){
                return;
            }
			let url = docApi.translationRandomDoc;
			let postData = {
				domain: this.selectedEngine.domain,
				engine: this.selectedEngine.key.split("@")[0],
                engineKey: this.selectedEngine.key,
				sourceLang: this.form.originValue,
				sourceText: this.form.docValue,
				targetLang: this.form.targetValue,
			};
			if (this.selectedEngine.modelId) {
				postData.modelId = this.selectedEngine.modelId;
			}
			this.queryTransitionResultLoading = true;
			this.$ajax.post(url, postData).then((res) => {
                if (res.status === 200) {
                    let data = res.data;
                    this.form.resultValue = "<p>" + data.target.replace(/\n/g, "</p><p>") + "</p>";
                }
            }).finally(() => {
                this.queryTransitionResultLoading = false;
            });
		},
		changeEngineEvent(engine) {
            /* if(!engine.enabled){
                this.$message.error('个性引擎因会员到期已下架，续订后会恢复服务~');
                return false;
            } */
            if (engine.proUsed){
                this.$message({
                    // showClose: true,
                    type: 'warning',
                    dangerouslyUseHTMLString: true,
                    message: 'Pro版专属引擎，升级为Pro版可用。<a href="/account/vip">去升级</a>',
                });
                return false;
            }else {
                /* if('chatgpt' === engine.key){
                    this.$message.warning('ChatGPT翻译速度较慢，如需快速翻译请选用其他引擎');
                } */
            }
			this.selectedEngine = engine;
			this.getEngineTransition();
            if(engine.modelId || this.selectedDomain.key === 'vertical'){
                this.getRandomSentences();
            }
            
		},

		backBtnEvent() {
			this.activeStepIndex = this.activeStepIndex - 1;
		},
		startTransitionBtnEvent: debounce(function() {
			let self = this;
			let url = projectApi.startTranslation;
			let docs = [];
            let keys = Object.keys(this.fileData || {});
            keys.map((key) => {
                docs.push(this.fileData[key]);
            });
			let postData = {
                projectName: this.defaultProjectName,
                remark: this.remark,
				docs: docs,
			};
			
            if (this.customTermId) {
				projectSetting.customTermId = this.customTermId;
			}
			let termIds = [];
			this.tabData.termsTableSelection.map((item) => {
				termIds.push(item.termId);
			});
			let memIds = [];
			this.tabData.memoryTableSelection.map((item) => {
				memIds.push(item.memId);
			});
            let projectSetting = {
                customTermId: this.customTermId ? this.customTermId : undefined,
                multilingualSetting: [{
                    sourceLang: this.form.originValue,
                    targetLang: this.form.targetValue,
                    engine: this.selectedEngine.key.split("@")[0],
                    domain: this.selectedEngine.domain,
                    modelId: this.selectedEngine.modelId || '',
                    randomEngine: this.selectReferenceEngine ? this.selectReferenceEngine.key : null,
                    termIds,
                    memIds,
                    memMatchingRate: this.memMatchingRate,
                }],
			};
            //QA
            //projectSetting['qaSetting'] = {options: [].concat(this.qaSetting.basicList, this.qaSetting.aiList)};
            let optionsObject = {};
            [].concat(this.qaSetting.basicList, this.qaSetting.aiList).forEach(option => {
                optionsObject[option.code] = option.selected;
            });
            projectSetting['qaSetting'] = {selected: optionsObject};
            let docSetting = {};
            this.docSettingOpts.map(item=>{
                item.options.map(opt=>{
                    docSetting[opt.key] = opt.value;
                });
            });
            projectSetting['docSetting'] = docSetting;
            // projectSetting['docSplitSetting'] = this.docSplit;

			postData.projectSetting = projectSetting;

			this.startTranslationBtnLoading = true;
			this.$ajax.post(url, postData).then((res) => {
					this.startTranslationBtnLoading = false;
					if (res.status === 200) {
						setTimeout(() => {
                            this.createProjectApiSuccess = true;
							self.$router.push(`/project/index`);
						}, 1000);
					}
				})
				.finally(() => {
					this.startTranslationBtnLoading = false;
				});
		}),
		initTermsList() {
			this.termsTableSelection = [];
			let url = `${termApi.termDB}?pageNumber=1&pageSize=1000` +
				`&sourceLang=${this.form.originValue}&targetLang=${this.form.targetValue}&teamId=${this.teamId}`;
			this.$ajax.get(url).then((res) => {
				if (res.status === 200) {
					this.tabData.termsList = res.data.records;
					if (res.data.records.length > 0) {
						// this.advancedIsShow = true;
					}
                    if (res.data.records.length > 0){
                        this.$nextTick(() =>{
                            if(arguments.length){
                                let termId = arguments[0].termId;
                                for(let item of this.tabData.termsList){
                                    if(item.termId === termId){
                                        this.$refs.termsTable.toggleRowSelection(item, true);
                                        break;
                                    }
                                }
                            }
                        });
                    }
				}
			});
		},
		initMemoryList() {
			this.memoryTableSelection = [];
			let url = `${memoryApi.memoryDB}?pageNumber=1&pageSize=1000&type=new` +
				`&sourceLang=${this.form.originValue}&targetLang=${this.form.targetValue}&teamId=${this.teamId}`;
			this.$ajax.get(url).then((res) => {
				if (res.status === 200) {
					this.tabData.memoryList = res.data.records;
                    if (res.data.records.length > 0){
                        this.$nextTick(() =>{
                            if(arguments.length){
                                let memId = arguments[0].memId;
                                for(let item of this.tabData.memoryList){
                                    if(item.memId === memId){
                                        this.$refs.memoryTable.toggleRowSelection(item, true);
                                        break;
                                    }
                                }
                            }
                        });
                    }
				}
			});
		},
		gotoTermPage() {
            this.$refs.createTermDialog.setShow(true);
		},
		gotoMemoryPage() {
            this.$refs.createMemoryDialog.setShow(true);
		},
		handleTermsTableSelectionChange(val) {
			this.tabData.termsTableSelection = val;
		},
		handleMemoryTableSelectionChange(val) {
			this.tabData.memoryTableSelection = val;
		},
		gotoDetailPage(engine) {
			let newpage = this.$router.resolve({
				path: "/engine/detail",
				query: { id: engine.modelId },
			});
			window.open(newpage.href, "_blank");
		},
		gotoNewPage() {
			let newpage = this.$router.resolve({ path: "/engine/ragCreate" });
			window.open(newpage.href, "_blank");
		},
        upgradeProBtnEvent(){
            this.$router.push('/account/vip');
        },
        docSettingCheckAllChange(val,index){
            this.docSettingOpts[index]['checkAll'] = val;
            this.docSettingOpts[index]['indeterminate'] = false;
            let tmpOptions = this.docSettingOpts[index]['options'];
            tmpOptions.map((opt, oIndex)=>{
                tmpOptions[oIndex]['value'] = val;
            });
            this.docSettingOpts[index]['options'] = tmpOptions;
        },
        docSettingCheckChange(val,index,oIndex){
            let checkedCount = 0;
            this.docSettingOpts[index]['options'][oIndex]['value'] = val;
            let options = this.docSettingOpts[index]['options'];
            options.map(opt=>{
                if(opt.value === true){
                    checkedCount = checkedCount + 1;
                }
            });
            this.docSettingOpts[index]['checkAll'] = checkedCount === options.length;
            this.docSettingOpts[index]['indeterminate'] = checkedCount > 0 && checkedCount < options.length;
        },
        getRandomSentences(){
            this.randomSentences = [];
            this.randomDocBtnShow = false;
            if(!this.selectedEngine){
                return;
            }
            let url = docApi.queryRandomParagraphList;
            let postData = {
                source: this.form.originValue,
                domain: this.selectedEngine.domain,
            }
            if (this.selectedEngine.modelId) {
				postData.modelId = this.selectedEngine.modelId;
			}
            this.$ajax.post(url, postData).then(res => {
                if(res.status == 200){
                    this.randomSentences = res.data || [];
                    // this.form.docValue = '';
                    this.randomDocBtnShow = this.randomSentences.length > 0;
                }
            })
        },
        randomDocBtnEvent(){
            this.randomDocBtnLoading = true;
            let index = Math.floor(Math.random() * this.randomSentences.length);
            this.form.docValue = this.randomSentences[index].sourceText;
            this.randomSentenceLable = this.randomSentences[index].tags;
            this.$nextTick(() => {
                this.randomDocBtnLoading = false;
                this.getEngineTransition();
            });
        },
        initQASetting(){
            let url = `${qaApi.queryQaConfig}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let list = res.data.options;
                    list.map(item=>{
                        if(item.type === 'BASIC'){
                            this.qaSetting.basicList.push(item);
                        }else if(item.type === 'AI'){
                            this.qaSetting.aiList.push(item);
                        }
                    });
                }
            })
        },
        initReferenceEngineList(){
            let url = projectApi.queryReferenceEngineList;
            this.$ajax.post(url, {
                domain: this.selectedEngine.domain,
				engine: this.selectedEngine.key.split("@")[0],
				sourceLang: this.form.originValue,
				targetLang: this.form.targetValue,
                modelId: this.selectedEngine.modelId ? this.selectedEngine.modelId : undefined,
            }).then(res => {
                if(res.status === 200){
                    this.referenceEngineList = res.data;
                }
            })
        },
        referenceEngineEvent(engine){
            if(this.selectReferenceEngine && this.selectReferenceEngine.key === engine.key){
                this.selectReferenceEngine = null;
            }else{
                this.selectReferenceEngine = engine;
            }
        },
        settingTabEvent(item, index){
            this.tabData.tabActiveIndex = index;
            if(index === 4){
                this.initReferenceEngineList();
            }
        },

        executeAction(action, params){
            switch (action){
                case 'updateLang':
                    this.form = { ...this.form, ...params, };
                    break;
                case 'updateFile':
                    this.uploadFileList = params.fileList;
                    this.fileData = params.fileData;
                    break;
            }
            this.$forceUpdate();
        },
	},
	beforeRouteLeave: function (to, from, next) {
        try{
            if (!this.createProjectApiSuccess){
                if (this.uploadFileList.length > 0) {
                    // next(false);
                    this.$confirm("确定要离开吗？离开会清空当前配置信息", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        next();
                    });
                } else {
                    next();
                }
            }else{
                next();
            }
        }catch(e){
            console.error('页面跳转提醒：',e);
        }
	},
    created(){
        this.initQASetting();
    },
	mounted() {
        
	},
	beforeDestroy() {
		
	},
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
.index-page {
	// position: relative;
	// margin: 20px;
	display: flex;
	height: 100%;
	flex-direction: column;
    .try-content {
		::v-deep .el-textarea__inner {
			height: 100%;
			border: none;
			background-color: inherit;
			padding: 20px;
			font-size: 14px;
			color: #000000;
		}
		p {
			line-height: 22px;
			&:empty {
				height: 22px;
			}
		}
	}
	.steps-content {
		width: 100%;
		display: flex;
        margin-top: 30px;
        justify-content: center;
		.step-item {
			display: flex;
			font-size: 15px;
			color: #00000066;
			&.active {
				.step-name {
					font-weight: 600;
					color: #000;
				}
			}
			.step-arrow {
				width: 44px;
				text-align: center;
			}
		}
	}
	.upload-content {
		display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
	}
    .next-btn {
        text-align: center;
        margin-top: 20px;
        height: 40px;
    }
	.engine-content {
        margin-top: 40px;
		margin-bottom: 100px;
		.from-content {
			display: flex;
			justify-content: center;
			align-items: center;
			.transition-icon {
				width: 64px;
				cursor: pointer;
				text-align: center;
				& > i {
					font-size: 18px;
				}
				&[disabled] {
					cursor: not-allowed;
					pointer-events: none;
					opacity: 0.5;
				}
			}
			.terminology-config {
				margin-left: 80px;
			}
		}
		.try-content {
			margin-top: 20px;
			display: flex;
			justify-content: center;
			.left-content {
				flex: 1;
				display: flex;
				flex-direction: column;
				max-width: 400px;
				min-width: 250px;
				min-height: 304px;
				background: #ffffff;
				border: 1px solid rgba(0, 0, 0, 0.1);
				box-sizing: border-box;
				border-radius: 8px;
				& > .input-content {
					flex: 1;
					position: relative;
					.el-textarea {
						height: 100%;
					}
					> .clear-btn {
						position: absolute;
						width: 30px;
						height: 30px;
						top: 0;
						right: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
					}
				}
				& > .action-content {
					display: flex;
                    justify-content: space-between;
					margin: 20px;
					font-size: 12px;
					> .random-doc-btn {
						text-align: left;
						cursor: pointer;
                        color: $a-color;
						&[disabled]{
                            opacity: .5;
                            pointer-events: none;
                            cursor: not-allowed;
                        }
                        
					}
                    > .tag-content {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        color: #666;
                    }
					> .word-number {
						width: 50px;
						text-align: right;
					}
				}
			}
			.center-content {
				width: 430px;
				margin-left: 40px;
				margin-right: 40px;
				> .field-type-list {
					display: flex;
					border-bottom: 1px solid #0000000d;
					> .item {
						flex: 1;
						font-size: 16px;
						color: #00000066;
						text-align: center;
						margin-bottom: 20px;
						cursor: pointer;
						/* &:first-child{
                            text-align: left;
                        }
                        &:last-child{
                            text-align: right;
                        } */
						&.active {
							color: #000000;
							font-weight: 500;
						}
					}
				}
				> .engine-list {
					margin: 20px 0;
                    display: grid;
                    grid-gap: 20px;
                    grid-template-columns: repeat(auto-fill,minmax(130px, 1fr));
					.item {
                        position: relative;
                        overflow: hidden;
						display: flex;
                        align-items: center;
						cursor: pointer;
						width: 130px;
						height: 44px;
						background-color: #ffffff;
						box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
						box-sizing: border-box;
						border: 1px solid #ffffff;
						border-radius: 6px;
						&.active {
							border-color: #000000;
							// color: #21B780;
						}
						&[disabled],
						&:disabled {
							cursor: not-allowed;
							// pointer-events: none;
							opacity: 0.5;
						}
						&.add-engine {
							justify-content: center;
							svg {
								font-size: 20px;
							}
						}
						> .engine-icon {
							margin-left: 8px;
							font-size: 22px;
						}
						> .engine-name {
							font-size: 13px;
                            line-height: 24px;
							flex: 1;
							margin-left: 5px;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
						> .engine-more {
							width: 20px;
                            height: 100%;
                            line-height: 44px;
							text-align: center;
							border-radius: 0 6px 6px 0;
							&:hover {
								background-color: #ededed;
							}
							svg {
								font-size: 14px;
							}
						}
					}
				}
			}
			.right-content {
				flex: 1;
				padding: 20px;
				max-width: 400px;
				min-width: 250px;
				min-height: 304px;
                max-height: 619px;
                overflow-y: auto;
				background-color: #ededed;
				border: 1px solid rgba(0, 0, 0, 0.1);
				box-sizing: border-box;
				border-radius: 8px;
				color: #000000;
				::v-deep p{
                    line-height: 22px;
                    font-size: 14px;
                }
			}
		}
		> .advanced-setup-content {
			min-width: 957px;
			max-width: 1310px;
			margin: auto;
			margin-top: 20px;
			background-color: #ffffff;
			border: 1px solid rgba(0, 0, 0, 0.1);
			box-sizing: border-box;
			border-radius: 6px;
			> .title-content {
				display: flex;
				align-items: center;
				height: 50px;
				> .left {
					width: 95px;
					margin-left: 40px;
					.title {
						font-weight: 500;
						font-size: 15px;
						line-height: 21px;
						color: #000000;
						svg {
							font-weight: 600;
							margin-left: 6px;
						}
					}
					.subtitle {
						font-size: 12px;
						line-height: 17px;
						color: #00000066;
						margin-top: 4px;
					}
				}
				> .center {
					flex: 1;
					height: 100%;
					
				}
				> .right {
					width: 60px;
					height: 100%;
					line-height: 70px;
					text-align: center;
					font-size: 20px;
					cursor: pointer;
					display: flex;
					align-items: center;
					svg {
						transition: transform 0.2s linear;
					}
					&.show {
						svg {
							transform: rotateZ(135deg);
						}
					}
				}
			}
			.advanced-content {
				border-top: 1px solid #0000001a;
				margin: 0 40px 40px 40px;
			}
		}
	}
    .setting-content{
        width: 860px;
        margin: 0 auto;
        .project-info{
            ::v-deep .el-input__inner{
                border: none;
                font-size: 15px;
                font-weight: 600;
                color: #000000;
            }
        }
        .tab-list {
            border-bottom: 1px solid #0000001A;
            height: 100%;
            display: flex;
            > .item {
                height: 100%;
                display: flex;
                padding-bottom: 15px;
                align-items: center;
                color: #00000066;
                font-size: 15px;
                box-sizing: border-box;
                margin-right: 40px;
                cursor: pointer;
                &.active {
                    color: #000000;
                    font-weight: 600;
                    border-bottom: 2px solid #000000;
                }
            }
        }
        .action-content {
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            // background-color: #f8f8f8;
        }
        ::v-deep .el-table .cell {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .qa-setting {
            .block-title{
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 10px;
            }
            .option-list{
                display: flex;
                margin-bottom: 10px;
                flex-wrap: wrap;
                .item{
                    width: 25%;
                    padding: 12px 0;
                    word-break: break-all;
                    word-wrap: break-word;
                }
            }
        }
        .backup-engine-wapper {
            .group-name {
                font-size: 12px;
            }
            .engine-group {
                margin-top: 20px;
                margin-bottom: 20px;
                display: grid;
                grid-gap: 15px;
                grid-template-columns: repeat(auto-fill,minmax(119px,1fr));
                .item {
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #F8F8F8;
                    border-radius: 4px;
                    box-sizing: border-box;
                    border: 1px solid transparent;
                    font-size: 18px;
                    cursor: pointer;
                    &.active {
                        border-color:#000000;
                        cursor: default;
                    }
                    .name {
                        font-size: 12px;
                        margin-left: 4px;
                    }
                }
            }
        }
        ::v-deep .el-input-number__decrease{
            background: rgb(248, 248, 248);
        }
        ::v-deep .el-input-number__increase{
            background: rgb(248, 248, 248);
        }
    }
	.term-extract-list {
		margin-bottom: 40px;
		.tips {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.5);
			margin-bottom: 20px;
		}
		.retrans-tips {
			margin-top: 10px;
		}
		.el-button--text {
			color: $font-primary-color;
			&:hover,
			&foucs {
				color: $font-primary-color-hover;
			}
			&.delete-btn {
				color: $font-danger-color;
				&:hover,
				&foucs {
					color: $font-danger-color-hover;
				}
			}
		}
	}
    .mem-matching-setting{
        ::v-deep .el-input__inner{
            border-radius: 50px;
        }
    }
}
.bold-form-label{
    ::v-deep .el-form-item__label{
        font-weight: bold;
        color: #000;
    }
}
</style>
